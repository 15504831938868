<template>
  <div class="body">
    <top></top>
    <!-- 广告图 -->
    <div class="advertisement_box">
      <div class="advertisement">
        <img :src="imga" alt="" />
      </div>
    </div>
    <!-- 搜索框 -->
    <div :class="scrollTop > 150 ? 'fixedSearch' : ''" class="seek">
      <div :class="scrollTop > 150 ? 'fixedSearchNext' : ''" class="seek-input">
        <div class="seek-box">
          <div class="seek-one">
            <div class="seek-one-icon">
              <img src="../assets/home/seek.png" alt="" />
            </div>
            <div class="seek-one-input">
              <input
                type="font"
                placeholder="搜索您想要的内容"
                v-model="keywords"
                @keyup.enter="toSearch"
              />
            </div>
            <div class="seek-one-button">
              <button @click="toSearch">搜索</button>
            </div>
          </div>
        </div>
        <!-- 搜索框下面的文字 -->
        <!-- <div class="seek_input_font">  :class="scrollTop > 150 ? 'disNone' : ''"  -->
        <div class="seek_input_font">
          <ul>
            <li
              v-for="(item, index) in Populartags"
              :key="index"
              @click="hotTg(item)"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 当前位置 -->
    <div class="place_box">
      <div class="place">
        <div class="place_image">
          <img src="../assets/Search_page/109.png" alt="" />
        </div>
        <!-- 位置 -->
        <div class="place_font">
          <p>当前位置：</p>
          <p>首页</p>
          <p>></p>
          <p>搜索</p>
        </div>
      </div>
    </div>
            <!-- 横幅 -->
    <div class="hengfu" @click="toDing"><img src="../assets/vv.jpg" alt=""></div>
    <!-- 分类 -->
    <div class="classify_list">
      <div class="classify_box">
        分类：
        <div>
          <span @click="selectAll" :class="activeIndex == -1 ? 'btnAtivetwo' : ''"
            >全部</span
          >
          <span
            v-for="(item, index) in btns"
            :key="index"
            :class="activeIndex == index ? 'btnAtivetwo' : ''"
            @click="handleToggle(index, item.id)"
            >{{ item.title }}</span
          >
          <span
            @click="selectInformation(btnLast.id)"
            :class="activeIndex == -2 ? 'btnAtivetwo' : ''"
            >{{ btnLast.title }}</span
          >
        </div>
      </div>
    </div>

    <div class="numBox">共有<span>{{pagerData?pagerData.total_count:0}}</span>条关于<span>“{{inpVal}}”</span>的内容</div>
    <!-- 主体内容 -->
    <div class="body_content_box">
      <!-- 内容 -->
      <div class="body_content" v-if="isHaveData">
        <div v-for="item in listData" :key="item.id">
          <div
            class="one"
            v-if="!item.reimg"
            @click="toText(item.id, item.type)"
          >
            <div class="tab-1">
              <!-- 标题 -->
              <div class="tab-title-font">
                <!-- 前面的竖线 -->
                <div class="tab-one"></div>
                <div class="tab-title">
                  <p>{{ item.title }}</p>
                </div>
              </div>
              <!-- 文字内容 -->
              <div class="tab-font">
                <p
                  v-html="colorkeyword(item.description)"
                  v-if="item.description != null"
                ></p>
                <p v-html="colorkeyword(item.content)" v-else></p>
              </div>
              <!-- 标签 -->
              <div class="label">
                <div v-for="itm in item.tagestitle" :key="itm.name">
                  {{ itm.name }}
                </div>
              </div>
              <div class="moreBtn">查看更多</div>
              <!-- 个人信息 -->
              <div class="personal">
                <p><img src="../assets/home/1.png" alt="" /></p>
                <p>{{ item.adminUser }}</p>
                <p>{{ item.updated_at }}</p>
              </div>
            </div>
          </div>

          <div class="box" v-else @click="toInfomationContent(item.id)">
            <!-- 有图的 -->
            <div class="tab-1 tab_2">
              <!-- 图片 -->
              <div class="tab_image">
                <img :src="item.reimg" alt="" />
              </div>
              <!-- 底部信息 -->
              <div class="tab_content_box">
                <div class="tab_content_top">
                  <h3>{{ item.title }}</h3>
                </div>
                <div class="tab_content_bottom">
                  <div class="tab_content_left">
                    {{ item.description }}
                  </div>
                  <div class="tab_content_right">{{ item.updated_at }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="body_content dataNone" v-else>
        <img src="../../public/images/datanone.png" alt="" />
        <p>未找到相关内容</p>
      </div>
      <!-- 分页 -->
      <div class="paging" v-if="pagerData != null">
        <el-pagination
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          hide-on-single-page
          background
          layout="prev, pager, next"
          :page-count="pagerData.total_page"
          :pager="pagerData.page_size"
          :current-page.sync="pagerData.current_page"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 横幅 -->
    <div class="hengfu" @click="toDing"><img src="../assets/vv.jpg" alt=""></div>
    <!-- 广告 -->
    <div class="advertising_boxs" v-show="isShow">
      <div class="advertising">
        <button class="occlude" @click="onclusa">×</button>
        <!-- 图片 -->
        <div class="advertising_image">
          <i><img src="../assets/home/vip.png" alt="" /></i>
        </div>

        <!-- 按钮 -->
        <div class="advertising_button">
          <button @click="kaitong">开通VIP</button>
        </div>
      </div>
    </div>
    <!-- 侧边栏 -->
    <sidebar></sidebar>
    <!-- 底部内容 -->
    <bottom></bottom>
    <loginM ref="loginM"></loginM>
  </div>
</template>
<script>
    import Qs from "qs";
    import axios from "axios";
    import loginM from "../components/loginM";
    import {
        getIndexSearchOne,
        getIndexSearchTwo,
        getsegmentsearch,
        getSearchTitle,
        getSearchInforData,
        getIndexplay,
        Populartags,
        getessayimg,
    } from "../http.js";
    export default {
        components: {
            loginM
        },
        data() {
            return {
                scrollTop: 0,
                isHaveData: true,
                btns: [],
                btnLast: {},
                activeIndex: -1,
                listData: [],
                keywords: "",
                inpVal: '',
                p: 1,
                tags: "",
                pagerData: {},
                valuea: {},
                isShow: false, //vip弹窗显隐
                Populartags: [], //热门段子
                imga: "",
                obj: {},
            };
        },
        created() {
            this.valuea = JSON.parse(sessionStorage.getItem("key"));
            // 监听页面滚动事件
            window.addEventListener("scroll", this.showSearch);
            let keywords = this.$route.query.keywords;

            this.keywords = keywords;
            this.getData(this.keywords);
            this.getTitleData();
            // 热门标签调用
            this.hotTag();
            this.essayimg();
        },
        methods: {
            // 到定制服务页
            toDing() {
                this.$router.push({
                    path: 'Customization?page=3'
                })
            },
            async essayimg() {
                var img = await getessayimg();
                this.imga = img.data;
            },
            // 热门标签点击事件
            hotTg(item) {
                this.keywords = item;
                this.toSearch();
                this.colorkeyword(item);
            },
            // 热门标签
            async hotTag() {
                let res = await Populartags();
                if (res.static == 1) {
                    this.Populartags = res.data;
                }
            },
            //吸顶监听
            showSearch() {
                // 获取当前滚动条向下滚动的距离
                let scrollTop =
                    document.documentElement.scrollTop || document.body.scrollTop;
                // 当页面滚动到高度300px处时，显示搜索框
                this.scrollTop = scrollTop;
            },
            //渲染分类按钮
            async getTitleData() {
                var data = await getSearchTitle();
                var titleDataLast = [];
                var titleDataLast = data.pop();
                this.btns = data;
                this.btnLast = titleDataLast[0];
            },
            //跳转详情
            async toText(id, type) {
                let that = this;
                console.log(this.valuea);
                
                this.tabAcitve(id, type);

                return
                // 没有扫过码
                if (that.valuea == null) {
                    that.$refs.loginM.qq();
                }

                // 扫码过没有注册账号
                if (that.valuea.uid == "" || that.valuea.phone == "") {
                    that.$message({
                        message: "请注册账号",
                        type: "warning",
                        duration: 2000,
                    });
                    that.$refs.loginM.asd = false; //注册遮罩显示
                    // 手机号注册显示开始
                    that.$refs.loginM.erbtna = false;
                    that.$refs.loginM.erbtn = true;
                    // 手机号显示结束
                    return;
                }

                //普通会员
                if (this.valuea.class == 0) {
                    $.ajax({
                        type: "POST",
                        dataType: "json",
                        url: "/api/shopmini/wap_UserContents.html",
                        async: false, //同步请求
                        data: {
                            uid: that.valuea.uid,
                            login_key: that.valuea.openid
                        },
                        success: function(res) {
                            console.log(res)
                            if (res.snum > 0) {
                                that.tabAcitve(id);
                                return;
                            }
                            if (res.snum == 0) {
                                // 判断普通会员用户观看次数
                                that.$message({
                                    message: "今日浏览次数已用完",
                                    type: "warning",
                                });
                                that.isShow = true; //vip弹框显示
                                return;
                            };
                        },
                        error: function(data) {
                            alert("正在加载请稍后！");
                        }
                    });
                }
                //非普通会员无限看
                if (this.valuea.class > 0) {
                    this.tabAcitve(id, type);
                }
            },

            // 开通vip弹窗确定按钮
            kaitong() {
                this.$router.push({
                    path: "/vip",
                });
            },
            // 关闭vip弹窗
            onclusa() {
                this.isShow = false;
            },
            // tab跳转
            tabAcitve(id, type) {
                //选项卡跳页面开始
                //判断跳转类型
                switch (type) {
                    case "3":
                        type = 2;
                        break;
                    case "2":
                        type = 0;
                        break;
                    case "1":
                        type = 1;
                        break;
                }
                if (this.activeIndex == 2) {
                    let goNewPath = this.$router.resolve({
                        name: "Text",
                        query: {
                            page: 1,
                            id,
                            keywords: this.keywords
                        },
                    });
                    window.open(goNewPath.href, "_blank");
                } else if (this.activeIndex == 1) {
                    let goNewPath = this.$router.resolve({
                        name: "Text",
                        query: {
                            page: 0,
                            id,
                            keywords: this.keywords
                        },
                    });
                    window.open(goNewPath.href, "_blank");
                } else if (this.activeIndex == 0) {
                    let goNewPath = this.$router.resolve({
                        name: "Text",
                        query: {
                            page: 2,
                            id,
                            keywords: this.keywords
                        },
                    });
                    window.open(goNewPath.href, "_blank");
                } else if (this.activeIndex == -1) {
                    let goNewPath = this.$router.resolve({
                        name: "Text",
                        query: {
                            page: type,
                            id,
                            keywords: this.keywords
                        },
                    });
                    window.open(goNewPath.href, "_blank");
                }
                // 选项卡结束
            },

            // 去行业资讯详情
            toInfomationContent(id) {
                this.$router.push({
                    name: "Content",
                    query: {
                        page: 5,
                        count: 1,
                        id,
                    },
                });
            },
            //点击搜素按钮
            toSearch() {
                if (this.keywords == "") {
                    alert("请填写搜索内容");
                    return;
                }
                this.activeIndex = -1;
                this.getData(this.keywords);
                this.colorkeyword(this.keywords);
            },
            //通过搜索获取数据并重组
            async getData(keywords) {
                let that = this;
                that.keywords = keywords;
                that.inpVal = keywords;
                let searchOne = await getIndexSearchOne(that.keywords, 1);
                let searchTwo = await getIndexSearchTwo(that.keywords, 1);
                if (searchOne.static !== 0) {
                    that.pagerData = searchOne.pages;
                    //如果第一个接口里有数据
                    if (searchTwo.static !== 0) {
                        //如果第二个接口里有数据
                        //拼接数据
                        var arr = searchOne.tages.concat(searchTwo);
                        //打乱数据
                        let newArr = that.shuffle(arr);
                        that.listData = newArr;
                        console.log("第一个有，第二个也有");
                        that.isHaveData = true;
                    } else {
                        that.listData = searchOne.tages;
                        console.log("第一个有，第二个没有");
                        that.isHaveData = true;
                    }
                } else {
                    that.pagerData = searchTwo.pages;
                    //如果第一个接口里没有数据
                    if (searchTwo.static !== 0) {
                        console.log("第一个没有，第二个有");
                        let newArr = searchTwo;
                        that.listData = newArr;
                        console.log(that.listData);
                        that.isHaveData = true;
                    } else {
                        console.log("第一个没有，第二个也没有");
                        let newArr = [];
                        that.listData = newArr;
                        console.log(that.listData);
                        that.isHaveData = false;
                    }
                }
            },

            //打乱数组
            shuffle(arr) {
                var len = arr.length;
                for (var i = 0; i < len - 1; i++) {
                    var index = parseInt(Math.random() * (len - i));
                    var temp = arr[index];
                    arr[index] = arr[len - i - 1];
                    arr[len - i - 1] = temp;
                }
                return arr;
            },
            //点击全选按钮
            selectAll() {
                this.obj.id = null;
                this.activeIndex = -1;
                this.getData(
                    this.keywords == "" ? this.$route.query.keywords : this.keywords
                );
            },
            //分类被点击改变样式
            async handleToggle(index, id) {
                let that = this;
                that.obj.id = id;
                that.obj.keywords = that.keywords;
                that.activeIndex = index;
                let sss = await getIndexplay(id, that.keywords, 1);
                that.listData = sss.tages;
                that.pagerData = sss.pages;
                that.isHaveData = true;
                console.log(that.pagerData);
            },
            //点击行业资讯
            async selectInformation() {
                let that = this;
                that.activeIndex = -2;
                let sss = await getSearchInforData(that.btnLast.id, that.keywords, 1);
                if (sss.stutic === 0) {
                    that.isHaveData = false;
                    that.pagerData = null;
                } else {
                    that.listData = sss;
                    that.pagerData = sss.pages;
                }
            },
            // 分页
            async handleSizeChange(val) {
                let that = this;
                that.p = val;
                var search = await getIndexSearchOne(that.keywords, that.p);
                if (search.static == 1) {
                    that.listData = search.tages;
                    that.pagerData = search.pages;
                    that.isHaveData = true;
                    return;
                } else {
                    that.isHaveData = false;
                }
            },
            async handleCurrentChange(val) {
                let that = this;
                that.p = val;
                if (that.obj.id != null) {
                    let sss = await getIndexplay(that.obj.id, that.obj.keywords, val);
                    that.listData = sss.tages;
                    that.pagerData = sss.pages;
                    return;
                }
                var search = await getIndexSearchOne(that.keywords, that.p);
                if (search.static == 1) {
                    that.listData = search.tages;
                    that.pagerData = search.pages;
                    that.isHaveData = true;
                    return;
                } else {
                    that.isHaveData = false;
                }
            },
        },
        destroyed() {
            window.removeEventListener("scroll", this.showSearch);
        },
        watch: {
            keywords() {
                sessionStorage.setItem('keywords', this.keywords)
            }
        }
    };
</script>
<style scoped>
    /* 广告 */
    
    .hengfu {
        width: 1200px;
        margin: 10px auto;
        cursor: pointer
    }
    
    .hengfu img {
        border-radius: 10px;
    }
    
    .advertising_boxs {
        width: 100vw;
        height: 100vh;
        /* background-color: rgb(238, 255, 0); */
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
        position: fixed;
        top: 0;
        /* display: none; */
    }
    
    .advertising {
        width: 600px;
        height: 600px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -300px;
        margin-left: -300px;
        /* background-color: red; */
    }
    
    .advertising_image {
        width: 600px;
        height: 490px;
    }
    
    .advertising_image img {
        width: 600px;
        height: 490px;
    }
    /* 按钮 */
    
    .advertising_button {
        width: 100%;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .advertising_button button {
        width: 150px;
        height: 50px;
        background: linear-gradient(90deg, #eac583, #dca545);
        border-radius: 25px;
        box-shadow: 0px 3px 6px 0px rgba(159, 116, 68, 0.1);
        font-size: 20px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        font-weight: 700;
    }
    
    .occlude {
        font-size: 25px;
        line-height: 30px;
        background-color: rgba(255, 255, 255, 0.5);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: absolute;
        right: 50px;
        top: 80px;
    }
    /* 广告结束 */
    /* input吸顶效果 */
    
    .fixedSearch {
        position: fixed;
        z-index: 2;
        height: 82px !important;
        background: rgb(255, 255, 255);
        top: 60px;
    }
    
    .fixedSearchNext {
        height: 90px !important;
    }
    
    .disNone {
        display: none;
    }
    /* 吸顶效果结束 */
    /* 分页 */
    
    .paging {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    /* 页面 */
    
    .body {
        width: 100%;
        min-width: 1200px;
        background-color: #fafafa;
    }
    /* 广告 */
    
    .advertisement_box {
        width: 100%;
        min-width: 1200px;
        margin: auto;
        padding-top: 70px;
        background-color: #ffffff;
    }
    
    .advertisement {
        width: 1200px;
        margin: auto;
    }
    /* 搜索 */
    
    .seek {
        width: 100%;
        height: 130px;
        min-width: 1200px;
        background-color: #ffffff;
    }
    
    .seek-input {
        width: 1200px;
        height: 130px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
    
    .seek-box {
        width: 750px;
        height: 60px;
        display: flex;
        align-items: center;
    }
    
    .seek-one {
        display: flex;
        align-items: center;
        border: 1px #f62959 solid;
        border-radius: 8px;
    }
    
    .seek-one-icon {
        width: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 12px;
    }
    
    .seek-one-icon img {
        width: 100%;
    }
    
    .seek-one-input {
        width: 590px;
        display: inline-block;
    }
    
    .seek-one-input input {
        width: 99%;
        line-height: 38px;
        font-size: 16px;
        color: #999999;
        vertical-align: middle;
        background: none;
    }
    
    .seek-one-input input:checked {
        color: #999999;
    }
    
    .seek-one-button {
        width: 114px;
        height: 40px;
    }
    
    .seek-one-button button {
        width: 114px;
        height: 40px;
        background-color: #f62959;
        color: #ffffff;
        border-radius: 0px 8px 8px 0px;
        cursor: pointer;
        font-size: 18px;
    }
    
    .seek_input_font {
        width: 540px;
        position: relative;
        top: -10px;
    }
    
    .seek_input_font ul {
        display: flex;
        justify-content: space-between;
    }
    
    .seek_input_font ul li {
        cursor: pointer;
        font-size: 14px;
        color: #999999;
    }
    
    .seek_input_font ul li:hover {
        color: #f62959;
    }
    /* 当前位置 */
    
    .place_box {
        width: 100%;
        min-width: 1200px;
        height: 60px;
        line-height: 60px;
        border-top: 1px #ededed solid;
    }
    
    .place {
        width: 1200px;
        height: 60px;
        line-height: 60px;
        margin: auto;
        display: flex;
    }
    /* 图标 */
    
    .place_image img {
        vertical-align: middle;
    }
    /* 位置文字 */
    
    .place_font {
        display: flex;
    }
    
    .place_font p {
        margin-left: 10px;
        color: #999999;
        font-size: 14px;
    }
    
    .place_font p:nth-of-type(2),
    .place_font p:nth-of-type(4) {
        cursor: pointer;
    }
    /* 分类 */
    
    .classify_list {
        width: 100%;
        min-width: 1200px;
        background-color: #ffffff;
        color: #333333;
    }
    
    .classify_list_a {
        margin-top: 10px;
    }
    
    .classify_box {
        width: 1200px;
        height: 80px;
        margin: auto;
        display: flex;
        align-items: center;
        font-size: 18px;
    }
    
    .classify_box>div {
        width: 95%;
        display: flex;
    }
    
    .classify_box>div>span {
        width: 104px;
        line-height: 34px;
        text-align: center;
        margin-left: 10px;
        border-radius: 17px;
        font-size: 16px;
        cursor: pointer;
        box-sizing: border-box;
        border: 1px solid transparent;
    }
    
    .btnAtivetwo {
        border: 1px #f62959 solid !important;
        /* box-sizing: border-box; */
        /* background-color: #F62959; */
        box-sizing: border-box;
        color: #f62959;
        width: 104px;
        line-height: 34px;
        text-align: center;
        margin-left: 10px;
        border-radius: 17px;
        font-size: 16px;
        cursor: pointer;
    }
    /* 搜索到内容数量 */
    
    .numBox {
        width: 1200px;
        margin: 10px auto;
        font-size: 14px;
        color: #333;
    }
    
    .numBox span {
        color: #f62959;
    }
    /* 主体内容 */
    /* .body_content_box {
  width: 100%;
  min-width: 1200px;
} */
    /* 主体内容 */
    
    .body_content_box {
        width: 100%;
        height: auto;
        /* min-height: 812px; */
        min-height: 800px;
        max-height: 1600px;
        min-width: 1000px;
        /* position: relative; */
        padding-bottom: 50px;
    }
    /* 内容的框 */
    
    .body_content {
        width: 1200px;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
    }
    
    .dataNone {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        line-height: 34px;
        font-size: 16px;
    }
    /* 内容 */
    
    .tab-1 {
        margin: 20px 20px 0px 0px;
        padding-left: 10px;
        padding-top: 2px;
        width: 380px;
        height: 265px;
        border-radius: 8px;
        background-color: #ffffff;
        cursor: pointer;
        box-shadow: 0px 0px 20px 0px rgba(246, 41, 89, 0.08);
        box-sizing: border-box;
        transition: 0.3s;
        position: relative;
    }
    
    .tab-1:hover {
        transform: scale(1.03);
        box-shadow: 0px 0px 20px 0px rgba(246, 41, 89, 0.15);
    }
    
    .tab_image {
        width: 100%;
        height: 190px;
        /* border-radius: 8px 8px 0px 0px; */
        /* border: 1px red solid; */
    }
    
    .tab_image img {
        width: 100%;
        height: 190px;
        border-radius: 8px 8px 0px 0px;
    }
    
    .tab-title>p:hover {
        color: #f62959;
    }
    
    .tab-title-font {
        height: 40px;
        display: flex;
        align-items: center;
        font-size: 20px;
        margin-top: 10px;
    }
    
    .tab-one {
        width: 6px;
        height: 22px;
        border-radius: 25px;
        vertical-align: middle;
        margin: 0px 10px 0px 10px;
        background-color: #f62959;
    }
    
    .tab-title {
        vertical-align: middle;
        font-size: 20px;
        color: #333333;
        font-weight: bold;
    }
    /* 文字内容 */
    
    .tab-font {
        width: 86%;
        height: 120px;
        padding-top: 15px;
        /* background-color: tan; */
        margin: auto;
        font-size: 15px;
        color: #666666;
    }
    
    .tab-font p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    /* 标签 */
    
    .label {
        width: 86%;
        margin: auto;
        /* background-color: red; */
        height: 30px;
        font-size: 12px;
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px #fee9ee solid;
    }
    
    .label div {
        padding: 3px 8px 3px 8px;
        display: inline-block;
        background-color: #fff7f2;
        color: #ff5c0d;
        border-radius: 2px;
        margin: 4px;
    }
    /* 个人信息 */
    
    .personal {
        width: 86%;
        margin: auto;
        /* background-color: green; */
        height: 50px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #666666;
    }
    
    .personal p:nth-of-type(1) {
        margin: 0px 10px 0px 10px;
    }
    
    .personal img {
        width: 24px;
        height: 24px;
        vertical-align: middle;
    }
    
    .personal p:nth-of-type(2) {
        width: 30%;
        /* background-color: red; */
    }
    
    .personal p:nth-of-type(3) {
        width: 60%;
        text-align: right;
        /* background-color: tan; */
    }
    /* 有图的 */
    
    .tab_2 {
        padding: 0;
    }
    /* 底部信息 */
    
    .tab_content_box {
        padding-left: 10px;
        color: #333333;
        line-height: 32px;
        margin-top: 10px;
    }
    
    .tab_content_bottom {
        display: flex;
        font-size: 14px;
    }
    
    .tab_content_top:hover {
        color: #f62959;
    }
    
    .tab_content_left {
        width: 77%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    
    .tab_content_right {
        width: 68%;
        text-align: right;
    }
</style>
<!-- 分页 -->
<style>
    .el-pager li {
        font-size: 12px;
    }
    
    .active {
        border: none !important;
        border-radius: 50% !important;
        background-color: #f62959 !important;
    }
    
    .number:hover {
        color: #fff !important;
        background-color: #f62959 !important;
    }
    
    .number {
        border-radius: 50% !important;
        border: 1px #999 solid !important;
    }
    
    .btn-prev {
        width: 75px !important;
        height: 32px !important;
        border-radius: 16px !important;
        color: #999999 !important;
        border: 1px #cccccc solid !important;
    }
    
    .btn-prev:hover {
        background-color: #f62959 !important;
        color: white !important;
    }
    
    .btn-prev>i::before {
        content: "上一页" !important;
        font-size: 14px;
        font-family: PingFang HK, PingFang HK-Medium !important;
    }
    
    .btn-next {
        width: 75px !important;
        height: 32px !important;
        border-radius: 16px !important;
        color: #999999 !important;
        border: 1px #cccccc solid !important;
    }
    
    .btn-next:hover {
        background-color: #f62959 !important;
        color: white !important;
    }
    /* 下一页 */
    
    .btn-next>i::before {
        content: "下一页" !important;
    }
    
    .moreBtn:hover {
        color: #f62959;
    }
    
    .moreBtn {
        position: absolute;
        width: 100%;
        right: 0;
        text-align: right;
        box-sizing: border-box;
        padding-right: 10px;
        color: rgb(180, 180, 180);
        margin-top: -20px;
        font-size: 14px;
        background: -moz-linear-gradient(top, rgb(233, 231, 231) 0%, rgba(0, 0, 0, 0) 100%);
        background: -webkit-linear-gradient(top, rgb(233, 231, 231) 0%, rgba(0, 0, 0, 0) 100%);
        background: -o-linear-gradient(top, rgb(233, 231, 231) 0%, rgba(0, 0, 0, 0) 100%);
        background: -ms-linear-gradient(top, rgb(233, 231, 231) 0%, rgba(0, 0, 0, 0) 100%);
        background: linear-gradient(to top, rgb(233, 231, 231) 0%, rgba(0, 0, 0, 0) 100%);
    }
</style>